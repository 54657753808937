import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {
  createStyles,
  TextInput,
  Title,
  Button,
  Container,
  Text,
  Center,
  Loader
} from '@mantine/core'
import { v4 as uuid } from 'uuid'

const VerifyContactInfo = ({ user, setUser }) => {
  const { classes } = useStyles()
  const history = useHistory()
  const [code, setCode] = useState('')
  const [sendingCode, setSendingCode] = useState(false)
  const [verifyingCode, setVerifyingCode] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [sendCodeCountdown, setSendCodeCountdown] = useState(null)
  const [timerDisplayString, setTimerDisplayString] = useState('')
  const [successKey] = useState(uuid()) // success key can be used to authenticate protected actions for 15 mins

  const contactMethod = user.email ? 'email' : 'phone'
  const contact = contactMethod === 'email' ? user.email : user.phone

  const sendAuthCode = () => {
    setSendingCode(true)
    setSendCodeCountdown(60 * 15) // 15 minutes
    setErrorMessage('')
    axios
      .get('/api/v0/auth/send-auth-code', {
        params: { contactMethod, contact }
      })
      .then(() => {
        setSendingCode(false)
      })
      .catch((err) => {
        setSendingCode(false)
        setErrorMessage(err.response?.data?.toString() || 'Server Error')
      })
  }

  const verifyAuthCode = () => {
    if (code.length !== 6) return setErrorMessage('Invalid Code')
    else setErrorMessage('')
    setVerifyingCode(true)
    axios
      .get('/api/v0/auth/verify-auth-code', {
        params: {
          contactMethod,
          contact,
          code,
          successKey
        }
      })
      .then(() => {
        history.go('/dashboard')
      })
      .catch((err) => {
        setVerifyingCode(false)
        setErrorMessage('Invalid Code' || err.response?.data?.toString())
      })
  }

  useEffect(() => {
    setTimeout(() => sendAuthCode(), 1000)
  }, [])

  useEffect(() => {
    if (code.length === 6) verifyAuthCode()
  }, [code])

  useEffect(() => {
    const newTime = sendCodeCountdown - 1
    if (newTime === -1) setSendCodeCountdown(undefined)
    if (newTime > -1) {
      setTimeout(() => {
        setSendCodeCountdown(newTime)

        const minutes = Math.floor(newTime / 60)
        const seconds = newTime % 60
        const timerDisplayString = `${minutes}:${
          seconds < 10 ? '0' : ''
        }${seconds}`
        setTimerDisplayString(timerDisplayString)
      }, 1000)
    }
  }, [sendCodeCountdown])

  const handleCodeChange = (e) => {
    const { value } = e.target
    if (value.length <= 6) {
      setCode(value)
    }
  }

  // This will only allow numbers for number input type
  const handleKeyDownChange = (e) =>
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

  const handleSignout = () => {
    axios.delete('/api/v0/auth').then(() => {
      setUser(null)
      history.replace('/signin')
    })
  }

  return (
    <Container className={classes.wrapper}>
      <Title order={2} sx={{ textAlign: 'center' }}>
        Please enter the confirmation code that was sent to:
      </Title>
      <Title order={2} sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {user.phone || user.email}
      </Title>
      <TextInput
        className={classes.input}
        mt={100}
        size="lg"
        label="Confirmation Code"
        value={code}
        onChange={handleCodeChange}
        onKeyDown={handleKeyDownChange}
        type="number"
      />
      {errorMessage ? (
        <Text className={classes.error} size="xl">
          {errorMessage}
        </Text>
      ) : null}
      <Center mt="lg">
        {sendingCode || verifyingCode ? (
          <Loader />
        ) : sendCodeCountdown ? (
          <Text size="xl">{timerDisplayString}</Text>
        ) : (
          <Button
            mt="xl"
            size="lg"
            radius="md"
            fullWidth
            onClick={sendAuthCode}
          >
            Send New Code
          </Button>
        )}
      </Center>
      <Button
        mt="lg"
        size="lg"
        radius="md"
        variant="subtle"
        fullWidth
        onClick={handleSignout}
        disabled={verifyingCode}
      >
        Sign Out
      </Button>
    </Container>
  )
}

export default VerifyContactInfo

const useStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: 556
  },
  input: {
    div: {
      input: {
        padding: 30,
        fontSize: 40,
        textAlign: 'center'
      }
    }
  },
  error: {
    color: theme.other.error
  }
}))
